import classNames from 'classnames/bind';
import React from 'react';

import { Button } from '@components/Button/Button';
import { CtaPanel } from '@components/CtaPanel/CtaPanel';
import { FaqSection } from '@components/FaqSection/FaqSection';
import { Feature } from '@components/Feature/Feature';
import {
  additionalVideoFeaturesFeature,
  costReductionFeature,
  securityFeature,
  videoCdnFeature,
} from '@components/Feature/features';
import { Image } from '@components/Image/Image';
import { Col } from '@components/Layout/Col/Col';
import { Head } from '@components/Layout/Head';
import { Row } from '@components/Layout/Row/Row';
import { LogosRow } from '@components/LogosRow/LogosRow';
import { MessageBar } from '@components/MessageBar/MessageBar';
import { Paragraph } from '@components/Paragraph/Paragraph';
import { ReviewsCarousel } from '@components/ReviewsCarousel/ReviewsCarousel';
import {
  reviewBanijay,
  reviewRakuten,
  reviewSport1,
  reviewTedX,
  reviewUdemy,
} from '@components/ReviewsCarousel/reviewsData';
import { Section } from '@components/Section/Section';
import { SmallTitle } from '@components/SmallTitle/SmallTitle';
import { Text } from '@components/Text/Text';
import { externalRoutes, routes } from '@routes';
import { cdnStats } from '@siteData';

import styles from './VideoCdn.module.scss';
import heroImage from './hero.png';
import htmlPlayerImage from './html5-player.png';
import lsProcessingImage from './ls-processing.png';
import vodWorkflowImage from './vod-workflow.png';
import banijayLogo from '@logos/Colored/banijay.svg';
import rouvyLogo from '@logos/Colored/rouvy.svg';
import sport1Logo from '@logos/Colored/sport1.svg';
import tedXPragueLogo from '@logos/Colored/tedXPragueDark.svg';
import udemyLogo from '@logos/Colored/udemy.svg';

const cx = classNames.bind(styles);

export const VideoCdn: React.FC = () => (
  <>
    <Head
      description={`Deliver high-quality video streaming at scale across all platforms & devices. Robust ${cdnStats.capacity.network} network that easily handles unexpected traffic spikes.`}
      title="Video CDN for VOD & live video platforms"
    />
    <Section padding={120}>
      <Row className="mb-8" verticalAlign="center">
        <Col lg={6}>
          <Text
            align="left"
            className="mb-4"
            color="gray900"
            isBalanced={false}
            size={56}
            tag="h1"
            weight="regular"
          >
            CDN optimized for <b>VOD and live video platforms</b>
          </Text>
          <Paragraph maxWidth={470} size="xl">
            MP4/HLS/MPEG-DASH/CMAF playback support for all platforms and devices.
          </Paragraph>
        </Col>
        <Col lg={6}>
          <Image alt="" height={390} src={heroImage} width={577} priority />
        </Col>
      </Row>
      <LogosRow
        logos={[
          { src: udemyLogo, name: 'Udemy', width: 81, height: 30 },
          { src: banijayLogo, name: 'Banijay', width: 90, height: 25 },
          { src: sport1Logo, name: 'Sport 1', width: 79, height: 23 },
          { src: tedXPragueLogo, name: 'TedX Prague', width: 103, height: 20 },
          { src: rouvyLogo, name: 'Rouvy', width: 80, height: 22 },
        ]}
        note="Trusted by"
      />
    </Section>
    <main>
      <Section padding={150} theme="tiles">
        <Text
          align="center"
          className="mb-4"
          color="gray900"
          display="block"
          isBalanced={false}
          maxWidth={560}
          size={44}
          tag="h2"
          isCentered
        >
          Global delivery of all major <b>HTTP video formats</b>
        </Text>
        <Paragraph align="center" className="mb-8" maxWidth={580} size="lg" isCentered>
          With {cdnStats.capacity.network} network capacity and PoPs across{' '}
          {cdnStats.continentsCount}
          &nbsp;continents, CDN77 secures the highest speed&nbsp;&amp;&nbsp;smooth delivery of live
          and on-demand videos, no matter where your viewers are.
        </Paragraph>

        <div className="mw-7 mx-auto">
          <Row gapX="xl" gapY="xl">
            {[
              videoCdnFeature,
              costReductionFeature,
              securityFeature,
              additionalVideoFeaturesFeature,
            ].map(feature => (
              <Col key={feature.title} gapX="xl" gapY="xl" md={6}>
                <Feature {...feature} maxWidth={320} />
              </Col>
            ))}
          </Row>
        </div>
      </Section>
      <Section overflow="hidden">
        <Text
          align="center"
          className="mb-7"
          color="gray900"
          display="block"
          size={30}
          tag="h2"
          weight="bold"
        >
          How satisfied clients are with CDN77
        </Text>
        <ReviewsCarousel
          data={[reviewRakuten, reviewUdemy, reviewSport1, reviewBanijay, reviewTedX]}
        />
      </Section>

      <CtaPanel title="Put your viewers in the front row." />

      <Section padding={{ top: 150, bottom: 120 }}>
        <SmallTitle color="gray">Additional video features</SmallTitle>
        <Text align="center" color="gray900" display="block" size={44} tag="h2">
          Accelerate your video workflow
        </Text>

        <Section padding={{ top: 120, bottom: 150 }} tag="div">
          <Row className={styles.row} horizontalAlign="between" verticalAlign="center">
            <Col lg={5} md={7}>
              <Text align="left" className="mb-3" color="gray900" size={30} tag="h3" weight="bold">
                VOD workflow
              </Text>
              <Paragraph maxWidth={380} size="lg">
                Upload your prerecorded videos to our CDN Storage and let it become your origin.
                Create files through API-based transcoding and serve them via CDN in formats of your
                choice.
              </Paragraph>
            </Col>
            <Col className={styles.imageWrapper} lg={7} md={5}>
              <Image alt="Bradmax HTML5 player" height={300} src={vodWorkflowImage} width={557} />
            </Col>
          </Row>
        </Section>
        <Section borderRadius="rounded" padding={90} tag="div" theme="dark" isPulled>
          <Row className={cx('row', 'isReversed')} verticalAlign="center">
            <Col lg={5} md={7}>
              <Text align="left" className="mb-3" color="white" size={30} tag="h3" weight="bold">
                Live streaming processing
              </Text>
              <Paragraph color="light" maxWidth={380} opacity={80} size="lg">
                Send us your live stream over standard protocols (RTMP, RTSP, HLS, MPEG-DASH,
                MPEG-TS) and let us convert and transcode your video to optimize viewer experience.
              </Paragraph>
            </Col>
            <Col lg={7} md={5}>
              <Image alt="Bradmax HTML5 player" height={375} src={lsProcessingImage} width={560} />
            </Col>
          </Row>
        </Section>
        <Section padding={{ top: 150, bottom: 0 }} tag="div">
          <Row className={styles.row} horizontalAlign="between" verticalAlign="center">
            <Col lg={5} md={7}>
              <Text align="left" className="mb-3" color="gray900" size={30} tag="h3" weight="bold">
                HTML5 player
              </Text>
              <Paragraph maxWidth={380} size="lg">
                On the frontend side, you can enhance your viewer experience with a fully
                customisable HTML5 player equipped with audience analytics which is available for
                both web and mobile application.
              </Paragraph>
            </Col>
            <Col className={styles.imageWrapper} lg={7} md={5}>
              <Image alt="Bradmax HTML5 player" height={399} src={htmlPlayerImage} width={515} />
            </Col>
          </Row>
        </Section>
      </Section>

      <MessageBar>
        Prices for additional video features are customized.{' '}
        <Button className={styles.link} href={routes.requestdOffer}>
          Request a tailored offer
        </Button>{' '}
        for your specific needs.
      </MessageBar>

      <Section padding={150}>
        <Text align="center" className="mb-8" color="gray900" display="block" size={44} tag="h2">
          Frequently asked questions
        </Text>
        <FaqSection
          data={[
            {
              question: 'Where can I find pricing information?',
              answer:
                "At CDN77, we've learned that one size doesn't fit all. Every client has unique requirements when it comes to video streaming services. Simply contact us describing your use case and we'll be happy to send you custom pricing.",
            },
            {
              question: 'How can you protect my video content?',
              answer: `We offer a <a href="${externalRoutes.client.securityMeasure}">range of features</a> to help you secure access to your content in every scenario imaginable. Whitelist & blacklist by geographical region and IP address, hotlink protection (domain lock), secure tokens as well as custom multi-DRM integrations using the following encryption keys  - PlayReady and Widevine (MPEG-DASH) and FairPlay (HLS). We also develop our own proprietary Hurricane® DDoS protection which mitigates outside attacks in under 10 seconds.`,
            },
            {
              question: 'Can I host my VOD library at CDN77?',
              answer: `Of course! Once you upload your files into the <a href="${externalRoutes.client.cdnStorage}">CDN77 Object Storage</a> it becomes your origin. Your content will be delivered worldwide in the most efficient way while decreasing the number of load requests.`,
            },
            {
              question: 'Does CDN77 have a video player?',
              answer:
                'Yes we cooperate with Bradmax player to enhance the playback experience on your front-end. It comes along with a vast range of audience analytics such as the number of real-time views, users, geolocation, device overview, engagement rate, and many others.',
            },
            // {
            //   question: 'Can CDN77 help with IPTV/OTT delivery?',
            //   answer:
            //     "Absolutely! We can help with the complete process from signal ingestion to a fully customized player. With CDN77 you can bring your entire video processing workflow under one roof. In fact, we've already helped many traditional TV broadcasters in setting up their end-to-end live streaming & VOD solution for their digital projects.",
            // },
          ]}
          isCtaVisible={false}
          openFirstItem
        />
      </Section>
    </main>
  </>
);
